export const tiposChecklist = {
    1: {nome: 'Simple Text input', habilitarValores: false},
    2: {nome: 'Large Text input', habilitarValores: false},
    3: {nome: 'Select menu', habilitarValores: true},
    4: {nome: 'Option menu', habilitarValores: true},
    5: {nome: 'Checkbox', habilitarValores: false}
}

export function getTipoString (tipo) {
    if (!tiposChecklist.hasOwnProperty(tipo)) return 'Tipo não definido'
    return tiposChecklist[tipo].nome
}

/*export function isBoolean ({tipo}) {
    if (!tiposChecklist.hasOwnProperty(tipo)) return false
    return tiposChecklist[tipo].isBoolean
}*/

export function isBoolean (fake) {
    return false
}
