<template>
  <window-content v-if="!loading && !errors && !success">
    <div class="wrapper-sm">
      <e-row class="m-b-sm">
        <e-col style="max-width: 150px">
          <erp-s-field
              view="lr"
              label="ID:"
              label-width="90px"
          >
            <erp-input simple-border v-model="campo.id" readonly/>
          </erp-s-field>
        </e-col>

      </e-row>
      <e-row>
        <e-col>
          <erp-s-field
              view="lr"
              label="Nome:"
              label-width="90px"
          >
            <erp-input autofocus simple-border v-model="campo.nome"/>
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row>
        <e-col style="max-width: 250px; display: flex;">
          <erp-s-field
              view="lr"
              label="Seção:"
              label-width="90px"
              style="display: flex;"
          >
            <erp-select
                placeholder="Qualquer"
                :options="arraySecoes"
                v-model="campo.secao"
            />
          </erp-s-field>
          <!--<u-icon name="plus-circle" type="fa" icon-style="light" class="m-l-xs cursor-pointer" @click.native="adicionaTipo" />-->
        </e-col>
      </e-row>

      <e-row>
        <e-col style="max-width: 250px; display: flex;">
          <erp-s-field
              view="lr"
              label="Tipo:"
              label-width="90px"
              style="display: flex;"
          >
            <erp-select
                placeholder="Qualquer"
                :options="arrayTiposAcessorio"
                v-model="campo.tipo"
            />
          </erp-s-field>
          <!--<u-icon name="plus-circle" type="fa" icon-style="light" class="m-l-xs cursor-pointer" @click.native="adicionaTipo" />-->
        </e-col>
      </e-row>

      <e-row>
        <e-col>
          <erp-s-field
              view="lr"
              label="Obrigatório:"
              label-width="90px"
          >
            <erp-checkbox v-model="campo.obrigatorio"/>
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row>
        <e-col>
          <erp-s-field
              view="lr"
              label="Público:"
              label-width="90px"
          >
            <erp-checkbox v-model="campo.publico"/>
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row>
        <e-col>
          <erp-s-field
              view="lr"
              label="Ativo:"
              label-width="90px"
          >
            <erp-checkbox v-model="campo.active"/>
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row>
        <e-col>
          <erp-s-field
              view="lr"
              label="Habilitar bind:"
              label-width="90px"
          >
            <erp-checkbox v-model="habilitarBind"/>
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row class="m-t-sm" v-if="habilitarBind">
        <e-col>
          <erp-s-field
              view="lr"
              label="Entidade:"
              label-width="90px"
          >
            <erp-input simple-border v-model="campo.entidadeBind"/>
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row class="m-b-sm" v-if="habilitarBind">
        <e-col>
          <erp-s-field
              view="lr"
              label="Atributo:"
              label-width="90px"
          >
            <erp-input simple-border v-model="campo.atributoBind"/>
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row class="m-b-sm">
        <e-col>
          <erp-s-field
              view="lr"
              label="Ordem:"
              label-width="90px"
          >
            <erp-input simple-border v-model="campo.order" />
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row class="m-b-sm">
        <e-col>
          <erp-s-field
              view="lr"
              label="Valor padrão:"
              label-width="90px"
          >
            <erp-input simple-border v-model="campo.valorPadrao"/>
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row class="row-valores" v-if="habilitarValores && campo.valores.length">
        <e-col style="max-width: 250px">
          <erp-s-field
              view="lr"
              :label="`Valor ${index + 1}:`"
              label-width="90px"
              v-for="(valor, index) in campo.valores" :key="index"
          >
            <div style="display: flex">
              <erp-input simple-border :value="valor" readonly/>
              <u-icon name="times" type="fa" icon-style="light"
                      class="cursor-pointer m-l-sm text-negative"
                      @click.native="removeValor(index)"
                      v-if="valor"
              />
            </div>
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row class="m-t-sm" v-if="habilitarValores">
        <e-col style="display: flex; max-width: 250px">
          <erp-s-field
              view="lr"
              label="Adicionar valor:"
              label-width="90px"
          >
            <erp-input simple-border v-model="tmpValor" ref="adicionaValor" @keyup.native.enter="addValor"/>
          </erp-s-field>
          <u-icon name="plus-circle" type="fa" icon-style="light" class="m-l-xs cursor-pointer"
                  @click.native="addValor"/>
        </e-col>
      </e-row>
    </div>

    <e-row>
      <e-col style="max-width: 250px; display: flex;">
        <erp-s-field
            label="Tipos de Bem:"
            view="lr"
            label-width="90px"
        >
          <erp-select multiple v-model="campo.tiposBem" placeholder="Nenhum" :options="tiposFiltered"
                      :loading="loadingTipos" size="2">
            <template v-slot:itemLabel="props">
                <span class="erp-select-level" :class="{'font-bold': Number(props.opt.level) === 0}"
                      :style="'padding: 0 0 0 ' + (10 * Number(props.opt.level)) + 'px' ">{{ props.opt.label }}</span>
            </template>
          </erp-select>
        </erp-s-field>
      </e-col>
    </e-row>

    <window-footer class="text-right">
      <div class="inline-block window-actions">
        <u-btn label="Cancelar" icon="chevron-left" icon-type="fa" icon-style="light" flat no-caps
               @click="$uloc.window.close($root.wid)"/>
        <u-btn label="Salvar (F2)" icon="save" icon-type="fa" icon-style="light" flat no-caps
               @click="save"
               v-shortkey="['f2']" @shortkey.native="save"/>
      </div>
    </window-footer>
  </window-content>
  <window-success v-else-if="success">
    <slot name="success">
      <div class="text-center">
        <u-icon name="check-circle" color="positive" type="fa" icon-style="solid" class="success-circle-140"/>
      </div>
      <h5 class="m-t" v-if="id">{{ id ? 'Atualizado com sucesso!' : 'Acessório registrado com sucesso!' }}</h5>
      <div class="text-center m-t">
        <timer-close-window ref="timer" @end="$uloc.window.close($root.wid)"/>
      </div>
    </slot>
  </window-success>
  <window-loading :errors="errors" :status="status" v-else/>
</template>

<script>
import {ErpCheckbox, ErpInput, ErpSelect, ErpSField, WindowContent, WindowFooter} from 'uloc-vue-plugin-erp'
import ERow from "../../../../layout/components/Row"
import ECol from "../../../../layout/components/Col"
import {
  getTipoString,
  isBoolean,
  tiposChecklist
} from "@/domain/remocao/helpers/gerenciamento-checklist"
import {
  find,
  newCampo,
  updateCampo
} from "@/domain/bem/services/campos"
import {
  list as listSecoes
} from "@/domain/bem/services/campoSecao"
import WindowSuccess from "../../../../layout/window/Success"
import TimerCloseWindow from "../../../../layout/window/TimerClose"
import WindowLoading from "../../../../layout/window/Loading"
import {listTiposBem} from "@/domain/cadastro/services"
import {treeCheck} from "@/components/cadastro/components/tiposbem/helpers/treeCheck"

export default {
  name: 'CampoWindow',
  props: ['id', 'props'],
  data() {
    return {
      loading: this.props && this.props.id ? !!this.props.id : !!this.id,
      errors: null,
      success: false,
      status: null,
      campo: {
        nome: null,
        secao: 1,
        obrigatorio: false,
        valorPadrao: null,
        valores: [],
        tiposBem: [],
        tipo: null,
        bindCampo: null,
        entidadeBind: null,
        atributoBind: null,
        order: 0,
        publico: false,
        active: false
      },
      tipo: {
        loading: true,
        serverData: []
      },
      tmpValor: null,
      showDashOptions: false,
      habilitarBind: false,
      loadingTipos: false,
      bemTipos: [],
      loadingSecoes: false,
      bemSecoes: []
    }
  },
  computed: {
    getId () {
      return this.props && this.props.id ? this.props.id : this.id
    },
    arrayTiposAcessorio() {
      const options = []
      for (const tipo in tiposChecklist) {
        options.push({label: getTipoString(tipo), value: +tipo})
      }
      return options
    },
    arraySecoes() {
      const options = []
      if (!this.bemSecoes || !this.bemSecoes.result) {
        return options
      }
      this.bemSecoes.result.map(secao => {
        options.push({label: secao.nome, value: secao.id})
      })
      return options
    },
    habilitarValores() {
      const tipo = tiposChecklist[this.campo.tipo] || {}
      return tipo.habilitarValores && !this.isTipoBoolean
    },
    isTipoBoolean() {
      return isBoolean(this.campo)
    },
    tiposFiltered() {
      if (!this.bemTipos) {
        return []
      }
      return this.treeCheck(this.bemTipos)
    }
  },
  mounted() {
    console.log(this.$props)
    this.loadingTipos = true
    listTiposBem(200, 1, null, true)
        .then(({data}) => {
          this.bemTipos = data
          console.log('Teste ', data)
          this.loadingTipos = false
          this.getId && this.load()
        })
        .catch(error => {
          console.error(error)
        })
    listSecoes(200, 1, null, true)
        .then(({data}) => {
          this.bemSecoes = data
          console.log('Seções ', data)
          this.loadingSecoes = false
          this.getId && this.load()
        })
        .catch(error => {
          console.error(error)
        })
  },
  methods: {
    changeTab({tab}) {
      this.tabActive = tab
    },
    load() {
      this.loading = true
      find(this.getId)
          .then(response => {
            const data = response.data
            if (data.tiposBem && Array.isArray(data.tiposBem)) {
              data.tiposBem = data.tiposBem.map(tipo => {
                return tipo.id ?? tipo
              })
            }
            this.campo = data
            this.habilitarBind = Array.isArray(this.campo.bindCampo) ? !!this.campo.bindCampo.length : !!this.campo.bindCampo
            if (this.habilitarBind) {
              this.campo.entidadeBind = this.campo.bindCampo?.class
              this.campo.atributoBind = this.campo.bindCampo?.value
            }
            if (data.secao && data.secao.id) {
              data.secao = data.secao.id
            }
            this.campo.valores = (this.campo.valores || []).map(item => {
              if (typeof item === 'string') return item
              return item.value
            })
          })
          .catch(error => {
            console.log(error)
            this.errors = error
          })
          .finally(() => {
            this.loading = false
          })
    },
    save() {
      this.loading = true
      const data = JSON.parse(JSON.stringify(this.campo))
      if (data.categoria?.id) {
        data.categoria = data.categoria.id
      }
      if (this.isTipoBoolean) {
        data.valores = {type: 'true/false'}
      }
      data.bindCampo = this.habilitarBind ? {class: data.entidadeBind, value: data.atributoBind} : []
      // data.bindCampo = JSON.stringify(data.bindCampo)
      data.tiposBem = data.tiposBem.filter(tipo => typeof tipo === 'number')
      const method = this.getId ? updateCampo(data) : newCampo(data)
      method
          .then(() => {
            const cbSuccess = () => {
              this.success = true
              this.$nextTick(() => {
                this.loading = false
                this.success = true
                this.$uloc.window.emit(this.$root.wid, 'update', this.getId)
                this.$refs.timer.start()
                // this.$uloc.window.close(this.$root.wid)
              })
            }
            cbSuccess()
          })
          .catch(error => {
            // this.errors = error.data
            this.alertApiError(error)
            console.error(error, error.data)
            this.success = null
            this.loading = false
          })
    },
    addValor() {
      const valor = (this.tmpValor || '').trim()
      if (!valor) return
      const valorJaExiste = this.campo.valores.indexOf(valor) !== -1
      if (valorJaExiste) return this.$uloc.notify({type: 'warning', message: 'Valor já adicionado'})
      this.campo.valores.push(valor)
      this.tmpValor = ''
      setTimeout(() => {
        this.$refs.adicionaValor.focus()
      }, 100)
    },
    removeValor(index) {
      this.campo.valores.splice(index, 1)
    },
    treeCheck
  },
  components: {
    WindowLoading,
    TimerCloseWindow,
    WindowSuccess,
    ECol,
    ERow,
    ErpSField,
    ErpInput,
    ErpSelect,
    // ErpTabs,
    // ErpTabItem,
    WindowContent,
    // WindowBody,
    WindowFooter,
    ErpCheckbox
  }
}
</script>

<style scoped>
.row-valores {
  padding: 10px !important;
  max-height: 100px;
  overflow-y: auto;
}
</style>
